html {
	box-sizing: border-box;
}

*, *::before, *::after {
	box-sizing: inherit;
}

body {
	margin: 0;
	background-color: darkslategray;
}
